<template>
  <div>
    <b-container fluid :style="{ backgroundColor: '#f5f5f5' }">
      <b-overlay :show="spinnerLoading">
        <b-row class="min-vh-100 py-5 justify-content-center">
          <b-col md="5" class="no-gutter">
            <div class="mx-auto bg-white rounded shadow-lg pb-5" style="margin: 0 auto; padding: 20px;">
              <div class="text-center mb-4">
                <img src="https://staffinmotion.s3.eu-central-1.amazonaws.com/reaching-app-web-app-icon.png" alt="ReachingApp logo" class="img-fluid" style="max-width: 120px;">
              </div>
              <b-row>
                <b-col cols="12">
                  <b-row>
                    <b-col cols="auto" v-if="lang2">
                      <FormField
                        tagname="b-form-select"
                        varname="language"
                        v-model="language"
                        class="mb-0 d-flex align-items-center"
                        style="gap: 0.5rem"
                      >
                        <b-form-select-option selected disabled value=""></b-form-select-option>
                        <b-form-select-option
                          v-for="language in $store.getters.languages"
                          :value="language.code.toUpperCase()"
                          :key="language.code"
                        >{{ language.text }}</b-form-select-option>
                      </FormField>
                    </b-col>
                  </b-row>
                  <div class="pb-3" style="border-bottom: 2px solid #dddddd"></div>
                </b-col>
              </b-row>
              <b-form @submit.prevent="register" class="mt-4">
                <b-row>
                  <b-col>
                    <h1>{{ $t("register_organization.pagename") }}</h1>
                    <p>{{ $t("register_organization.To get started, register your organization") }}</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" class="mt-4">
                    <h3>{{ $t("register_organization.Campaign dates") }}</h3>
                  </b-col>
                  <b-col class="mt-2">
                    <b-form-group :label="`${$t('register_organization.How long should the campaign last?')}:`">
                      <b-form-radio-group v-model="weeks" class="radio-group">
                        <b-form-radio value="4">4 {{ $t("register_organization.weeks") }}</b-form-radio>
                        <b-form-radio value="5">5 {{ $t("register_organization.weeks") }}</b-form-radio>
                        <b-form-radio value="6">6 {{ $t("register_organization.weeks") }}</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-3">
                    <b-form-group :label="`${$t('register_organization.start_date')}:`" label-for="mondays">
                      <b-form-select id="mondays" v-model="selectedMonday">
                        <option v-for="option in options" :key="option.value" :value="option.value">{{ option.text }}</option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="12" md="6" lg="6" xl="6" class="mb-3">
                    <b-form-group :label="`${$t('register_organization.Campaign will end')}:`" label-for="calculatedDate">
                      <b-form-input id="calculatedDate" :value="formattedCalculatedDate" v-model="formattedCalculatedDate" readonly class="inactive-input"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Contact Info -->
                <b-row class="mb-5 mt-3">
                  <b-col cols="12">
                    <h3>{{ $t("register_organization.contact_information") }}</h3>
                  </b-col>
                  <!-- First Name -->
                  <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-group :label="`${$t('first_name')}:`" label-for="first_name">
                      <b-form-input id="first_name" name="first_name" v-model="fName" type="text" required />
                      <small class="text-danger" v-if="error.first_name">{{ error.first_name[0] }}</small>
                    </b-form-group>
                  </b-col>
                  <!-- Last Name -->
                  <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-group :label="`${$t('last_name')}:`" label-for="last_name">
                      <b-form-input id="last_name" name="last_name" v-model="lName" type="text" required />
                      <small class="text-danger" v-if="error.last_name">{{ error.last_name[0] }}</small>
                    </b-form-group>
                  </b-col>
                  <!-- Email -->
                  <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-group :label="`${$t('email')}:`" label-for="email">
                      <b-form-input id="email" name="email" v-model="email" type="email" required />
                      <small class="text-danger" v-if="error.email">{{ error.email[0] }}</small>
                    </b-form-group>
                  </b-col>
                  <!-- Re-enter Email -->
                  <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-group :label="`${$t('repeat_email')}:`" label-for="re-email">
                      <b-form-input id="re-email" name="re-email" v-model="reEmail" type="email" required />
                      <b-form-invalid-feedback class="ml-2" :state="emailMatch">{{ $t("repeat_email-error") }}</b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Invoice Info Row -->
                <b-row class="mb-5 mt-4">
                  <b-col cols="12">
                    <h3>{{ $t("register_organization.invoice_information") }}</h3>
                  </b-col>
                  <!-- Company Org Name -->
                  <b-col cols="12">
                    <b-form-group :label="`${$t('register_organization.organization_name')}:`" label-for="org_name">
                      <b-form-input :disabled="showInvoiceException" id="org_name" name="org_name" v-model="orgName" type="text" required />
                      <small class="text-danger" v-if="error.invoice_company_name">{{ error.invoice_company_name[0] }}</small>
                    </b-form-group>
                  </b-col>
                  <!-- Street Address -->
                  <b-col cols="12">
                    <b-form-group :label="`${$t('register_organization.street_address')}:`" label-for="street_add">
                      <b-form-input :disabled="showInvoiceException" id="street_add" name="street_add" v-model="streetAdd" type="text" required />
                      <small class="text-danger" v-if="error.invoice_street_address">{{ error.invoice_street_address[0] }}</small>
                    </b-form-group>
                  </b-col>
                  <!-- Zip Code -->
                  <b-col cols="12" sm="12" md="4" lg="4">
                    <b-form-group :label="`${$t('register_organization.zip_code')}:`" label-for="zip_code">
                      <b-form-input :disabled="showInvoiceException" id="zip_code" name="zip_code" v-model="zipCode" type="text" required />
                      <small class="text-danger" v-if="error.invoice_zip_code">{{ error.invoice_zip_code[0] }}</small>
                    </b-form-group>
                  </b-col>
                  <!-- Zip  -->
                  <b-col cols="12" sm="12" md="8" lg="8">
                    <b-form-group :label="`${$t('register_organization.postal_district')}:`" label-for="zip">
                      <b-form-input :disabled="showInvoiceException" id="zip" name="zip" v-model="zip" type="text" required />
                      <small class="text-danger" v-if="error.invoice_zip">{{ error.invoice_zip[0] }}</small>
                    </b-form-group>
                  </b-col>
                  <b-col :cols="showVAT ? 6 : 12">
                    <b-form-group :label="`${$t('country')}:`" label-class="text-nowrap" :label-for="country">
                      <b-form-select style="min-width: 100px;" id="country" name="country" v-model="country" required>
                        <b-form-select-option selected disabled value=""></b-form-select-option>
                        <b-form-select-option v-for="countr in countries" :value="countr.country_code" :key="countr.country_code">{{ countr.country_name }}</b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <template v-if="showVAT">
                    <!-- VAT-number -->
                    <b-col cols="6">
                      <b-form-group :label="$t('register_organization.vat_number')" label-for="vat_number">
                        <b-form-input
                          :disabled="showInvoiceException"
                          id="vat_number"
                          name="vat_number"
                          v-model="vat_number"
                          type="text"
                          required
                          @input="vat_number_error = ''"
                        />
                        <small class="text-danger" v-if="vat_number_error">{{ vat_number_error }}</small>
                      </b-form-group>
                    </b-col>
                  </template>
                  <!-- Invoice Email Add  -->
                  <b-col cols="12" sm="12" md="12" lg="12">
                    <b-form-group :label="$t('register_organization.invoice_email')" label-for="invoice_email">
                      <b-form-input :disabled="showInvoiceException" id="invoice_email" name="invoice_email" v-model="invoiceEmail" type="email" />
                      <small block>{{ $t("register_organization.invoice_email_disclaimer") }}</small>
                    </b-form-group>
                  </b-col>
                  <!-- Invoice reference  -->
                  <b-col cols="12" sm="12" md="12" lg="12">
                    <b-form-group :label="`${$t('register_organization.invoice_reference')}:`" label-for="invoice_reference">
                      <b-form-input :disabled="showInvoiceException" id="invoice_reference" name="invoice_reference" v-model="invoiceReference" type="text" required />
                      <small class="d-block text-danger" v-if="error.invoice_reference">{{ error.invoice_reference[0] }}</small>
                      <small block>{{ $t("register_organization.invoice_reference_disclaimer") }}</small>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col cols="12">
                    <b-button
                      block
                      size="lg"
                      type="submit"
                      style="border-radius: 11px; font-size: 1.1rem; background-color: #E63375; color: white; border: none;"
                      :disabled="!isValid"
                      v-if="!spinnerLoading"
                    >
                      <span style="letter-spacing: 1px;">{{ $t("register_organization.send") }}</span>
                    </b-button>
                    <b-button style="border-radius: 11px; background-color: #00C782; color: white; border: none;" disabled v-if="spinnerLoading">
                      <b-spinner small></b-spinner>
                      <span class="sr-only">Loading...</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </div>
          </b-col>
          <b-col md="3" class="pt-5 no-gutter bg-soft-brand bg-soft-text right-col-border p-t-3">
            <div class="right-column">
              <h3>{{ $t("register_organization.Get Moving Challenge will help you to") }}</h3>
              <ul>
                <li>{{ $t("register_organization.Inspire your employees to positive change") }}</li>
                <li>{{ $t("register_organization.Strengthen the team spirit") }}</li>
                <li>{{ $t("register_organization.Create a fun atmosphere at your workplace") }}</li>
              </ul>
              <h3 class="mt-4">{{ $t("register_organization.Our guarantee") }}</h3>
              <ul>
                <li>{{ $t("register_organization.Cancel for free any time if you are not satisfied") }}</li>
                <li>{{ $t("register_organization.100% hassle free, sign up and let us onboard you") }}</li>
              </ul>
            </div>
          </b-col>
        </b-row>
      </b-overlay>
    </b-container>
  </div>
</template>


<script>
import axios from "axios"
import FormField from "@/components/FormField.vue"
export default {
  components: { FormField },
  watch: {

    selectedLocale: "generateOptions",

    language(newVal) {
      this.$store.dispatch("changeLanguage", newVal.toLowerCase())
    },

    calculatedDateValue(newValue) {
        this.calculatedDate = newValue;
    }    

                
  },
  data() {
    return {
      language: this.$store.getters.language.toUpperCase(), // Default language
      loading: false,
      validateParent: [],
      
      spinnerLoading: false,
      bg: this.$images.registerOrganizationBg,
      root_org_unit_id: null,
      organizational_parent_id: null,
      fName: "",
      lName: "",
      email: "",
      reEmail: "",
      phoneNumber: "",
      orgName: "",
      streetAdd: "",
      zipCode: "",
      zip: "",
      invoiceEmail: "",
      invoiceReference: "",                
      error: {},
      lang: null,
      lang2:null,
      countries: [],
      country: "",
      vat_number: "",
      vat_number_error: "",
      showInvoiceException:null,
      
      selectedMonday: null,
      options: [],
      weeks: "4",
      calculatedDate: null,
      selectedLocale: navigator.language,
      availableLocales: ["en-US", "fr-FR", "es-ES", 'sv-SE'],
      fallbackLocale: "en-GB", // Choose a suitable fallback locale

      countryToLocale: {
        SV: 'sv-SE',
        EN: 'en-GB',
        US: 'en-US',    
        // Add more country mappings as needed

      calculatedDateValue: null, 
      valid_to_dt: ''        
      }


    }
  },
  computed: {
    showVAT() {
      const currentCountry = this.countries.find(
        e => e.country_code === this.country
      )
      // fallback to not showing vat
      if (currentCountry) {
        return currentCountry?.ask_for_vat_number === "Y"
      } else {
        return false
      }
    },
    isValid() {
      return (
        this.fName &&
        this.lName &&
        this.email &&
        this.reEmail &&
        this.email === this.reEmail &&
        this.orgName &&
        this.streetAdd &&
        this.zipCode &&
        this.zip &&
        this.invoiceReference 
      )
    },
    emailMatch() {
      if (this.reEmail) {
        return this.email === this.reEmail
      }
      return true
    },
/*
      selectedMondayDate() {
        return new Date(this.selectedMonday);
      },
      calculatedDateValue() {
        const selectedMonday = new Date(this.selectedMondayDate);
        const calculatedDate = new Date(selectedMonday.getFullYear(), selectedMonday.getMonth(), selectedMonday.getDate() + 7 * (parseInt(this.weeks) - 1));

        // Find the nearest Sunday after the calculated date
        while (calculatedDate.getDay() !== 0) {
          calculatedDate.setDate(calculatedDate.getDate() + 1);
        }

        return calculatedDate;
      },
      formattedCalculatedDate() {
        const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
        return this.calculatedDateValue.toLocaleDateString(this.selectedLocale, options);
      }
*/

  calculatedDateValue() {
    const selectedMonday = new Date(this.selectedMonday);
    const calculatedDate = new Date(selectedMonday);
    calculatedDate.setDate(selectedMonday.getDate() + 7 * (parseInt(this.weeks) - 1));
    // Find the nearest Sunday after the calculated date
    while (calculatedDate.getDay() !== 0) {
      calculatedDate.setDate(calculatedDate.getDate() + 1);
    }
    return calculatedDate;
  },
  formattedCalculatedDate() {
    return this.calculatedDateValue.toLocaleDateString(this.selectedLocale, {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  }

  },
  methods: {

      generateOptions() {    
        // Get the current date
        var today = new Date();

        // Calculate the next Monday
        var nextMonday = new Date(today);
        nextMonday.setDate(today.getDate() + (1 + 7 - today.getDay()) % 7);

        // Calculate the date range (one month from next Monday)
        var endRange = new Date(nextMonday);
        endRange.setMonth(endRange.getMonth() + 3);

        // Generate the drop-down list options
        var options = [];
        while (nextMonday < endRange) {
          var option = {
            text: this.formatDate(nextMonday), // Use a function to format the date
            value: nextMonday.toISOString()
          };
          options.push(option);
          nextMonday.setDate(nextMonday.getDate() + 7);
        }

        // Set the options in the component data
        this.options = options;
        // Set the default value of the Monday drop-down to the first calculated Monday
        this.selectedMonday = this.options[0].value;
      },
      formatDate(date) {
        const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString(this.selectedLocale, options);
      },
  

    async getCountries() {
      try {
        const resp = await axios.get("/register-now/countries")
        this.countries = resp.data?.data ?? []
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message || "Unable to get countries",
        })
      }
    },

    resetInput() {
      this.fName = ""
      this.lName = ""
      this.email = ""
      this.reEmail = ""      
      this.orgName = ""
      this.streetAdd = ""
      this.zipCode = ""
      this.zip = ""
      this.invoiceEmail = ""
      this.invoiceReference = ""      
                      
      this.error = {}
      this.country = ""
      this.vat_number = ""
      this.vat_number_error = ""      
    },


    async register() {
      this.spinnerLoading = true

      const validFromDt = new Date(this.selectedMonday).toISOString().slice(0, 10)
      const validToDt = this.calculatedDateValue.toISOString().slice(0, 10)  // Use calculatedDateValue directly

      try {
        let data = {          
          valid_from_dt: validFromDt,
          //valid_to_dt: new Date(this.formattedCalculatedDate,).toISOString().slice(0, 10), // Format to YYYY-MM-DD
          valid_to_dt: validToDt,
          first_name: this.fName,
          last_name: this.lName,
          email: this.email,
          email_confirmation: this.reEmail,          
          invoice_company_name: this.orgName,
          invoice_street_address: this.streetAdd,
          invoice_zip_code: this.zipCode,
          invoice_zip: this.zip,
          invoice_reference: this.invoiceReference,          
        }
        if (this.invoiceEmail) {
          data["invoice_email"] = this.invoiceEmail
        }

        data["lang"] = this.$route.query.lang
        data["invoice_country_code"] = this.country
        if (this.showVAT) {
          data["vat_number"] = this.vat_number
          data["valid_vat_number"] = Boolean(
            this.vat_number && !this.vat_number_error
          )
        }

        const response = await axios.post(
          "/single-org-enrollment",
          data
        )
        if (response.status == 200) {
          localStorage.setItem("register-now-email", this.email)
          this.resetInput()
          this.$store.dispatch("addToastMessage", {
            type: "success",
            text: this.$t("register_organization.customer_registered_single_enrollment"),
          })
          setTimeout(
            () => this.$router.push({ name: "RegisterConfirmation" }),
            100
          )
        }
      } catch ({ response }) {
        this.error = Object.assign(response.data.error_details)
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.spinnerLoading = false
      }
    },

  },


    mounted() {
      this.generateOptions();
    },
        
  async created() {

    this.getCountries()

    const params = this.$route
    const url = params.fullPath

    const getLang = new URLSearchParams(url.split("?", 2)[1]).get("lang")
    if (getLang) this.lang = getLang
    if (this.$store.getters.languages.find(lang => this.lang == lang.code)) {
      this.$store.dispatch("changeLanguage", this.lang)
    }
  },
}
</script>

<style scoped>
.bg-white {
  background-color: #ffffff;
}

.text-dark {
  color: #333333;
}

.text-danger {
  color: #e74c3c;
}

.right-column {
  padding-left: 20px;
  padding-right: 20px;
}

.right-column h3 {
  padding-left: 10px;
  padding-right: 10px;
}

.right-column ul {
  padding-left: 20px;
  padding-top: 5px;
  margin-left: 20px;  
}

.right-column ul li {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.form-group >>> .form-control {
  border-radius: 4px !important;
  border: 1px solid #ddd;
  padding: 0.75rem;
  font-size: 1rem;
}

.form-group >>> .custom-select {
  border-radius: 4px !important;
  border: 1px solid #ddd;
  padding: 0.75rem;
  font-size: 1rem;
}

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inactive-input {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  color: #777;
  cursor: not-allowed;
}

.no-gutter {
  padding-right: 0;
  padding-left: 0;
}

@media (min-width: 992px) {
  .left-col-border {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .right-col-border {
    border-top-right-radius: 0.85rem;
    border-bottom-right-radius: 0.85rem;
  }

  .rounded {
    border-top-left-radius: 0.85rem;
    border-bottom-left-radius: 0.85rem;
  }
}

@media (min-width: 768px) {
  .custom-form-width {
    width: 65%;
    margin: 0 auto;
  }
}

.bg-soft-brand {
  background-color: #66D1B0;
}

.bg-soft-text {
  color: #333333;
}

@media (max-width: 767px) {
  .right-column {
    background-color: #66D1B0;
    color: #333333;
  }
}
</style>
