<template>
  <div>
    <b-container fluid :style="{ backgroundColor: '#f5f5f5' }">
      <b-overlay :show="spinnerLoading">
        <b-row class="min-vh-100 py-5 justify-content-center">
          <b-container class="min-vh-100 bg-white text-dark shadow-lg" style="max-width: 800px; border-radius: 12px; padding: 20px;">
            <div class="text-center mb-4">
              
              <img src="https://staffinmotion.s3.eu-central-1.amazonaws.com/reaching-app-web-app-icon.png" alt="ReachingApp logo" class="img-fluid" style="max-width: 120px;">
            </div>
            <b-row>
              <b-col cols="12">
                <b-row>
                  <b-col>
                    <h1 class="text-dark">{{ $t("register_organization.pagename") }}</h1>
                  </b-col>
                  <b-col cols="auto" v-if="!lang">
                    <FormField
                      tagname="b-form-select"
                      varname="language"
                      v-model="language"
                      class="mb-0 d-flex align-items-center"
                      style="gap: 0.5rem"
                    >
                      <b-form-select-option selected disabled value=""></b-form-select-option>
                      <b-form-select-option
                        v-for="language in $store.getters.languages"
                        :value="language.code.toUpperCase()"
                        :key="language.code"
                      >{{ language.text }}</b-form-select-option>
                    </FormField>
                  </b-col>
                </b-row>
                <div class="pb-3" style="border-bottom: 2px solid #dddddd"></div>
              </b-col>
            </b-row>
            <b-form @submit.prevent="register" class="mt-4">
              <!-- Organization belonging -->
              <b-row class="mb-3" v-if="!isOrgParentIdNull">
                <b-col cols="12">
                  <b-form-group
                    :label="`${$t('register_organization.organization_belonging')}:`"
                    label-for="selectOrganizationBelonging"
                  >
                    <b-form-select
                      v-model="selectOrganizationBelonging"
                      id="selectOrganizationBelonging"
                      class="mb-3"
                      @change="checkInternational"
                    >
                      <b-form-select-option :value="null" disabled>
                        {{ $t("register_organization.choose_organization") }}
                      </b-form-select-option>
                      <b-form-select-option
                        v-for="(parent, index) in validateParent"
                        :value="parent.id"
                        :key="index"
                      >
                        {{ parent.organizational_unit_name }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- Promo code -->
              <div class="mb-4" v-if="usePromoCodes">
                <b-form-group>
                  <b-row align-v="center">
                    <b-col cols="auto" class="mb-2">
                      <b-form-input
                        id="promotion_code"
                        name="promotion_code"
                        v-model="promotion_code"
                        type="text"
                        @keyup.enter="checkPromoCode"
                        style="max-width: 250px;"
                        :placeholder="$t('register_organization.promo_code')"
                      />
                    </b-col>
                    <b-col cols="auto" class="mb-2">
                      <b-button
                        size="lg"
                        variant="outline-primary"
                        class="rounded-sm"
                        style="font-size: 1rem;"
                        @click="checkPromoCode"
                      >
                        {{ $t("register_organization.promo_code-submit") }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form-group>
                <small v-if="promotion_description">{{ promotion_description }}</small>
              </div>

              <!-- Contact Info -->
              <b-row class="mb-4">
                <b-col cols="12">
                  <h3>{{ $t("register_organization.contact_information") }}</h3>
                </b-col>
                <!-- First Name -->
                <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group :label="`* ${$t('first_name')}:`" label-for="first_name">
                    <b-form-input
                      id="first_name"
                      name="first_name"
                      v-model="fName"
                      type="text"
                      required
                    />
                    <small class="text-danger" v-if="error.first_name">{{ error.first_name[0] }}</small>
                  </b-form-group>
                </b-col>
                <!-- Last Name -->
                <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group :label="`* ${$t('last_name')}:`" label-for="last_name">
                    <b-form-input
                      id="last_name"
                      name="last_name"
                      v-model="lName"
                      type="text"
                      required
                    />
                    <small class="text-danger" v-if="error.last_name">{{ error.last_name[0] }}</small>
                  </b-form-group>
                </b-col>
                <!-- Email -->
                <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group :label="`* ${$t('email')}:`" label-for="email">
                    <b-form-input
                      id="email"
                      name="email"
                      v-model="email"
                      type="email"
                      required
                    />
                    <small class="text-danger" v-if="error.email">{{ error.email[0] }}</small>
                  </b-form-group>
                </b-col>
                <!-- Re-enter Email -->
                <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group :label="`* ${$t('repeat_email')}:`" label-for="re-email">
                    <b-form-input
                      id="re-email"
                      name="re-email"
                      v-model="reEmail"
                      type="email"
                      required
                    />
                    <b-form-invalid-feedback class="ml-2" :state="emailMatch">
                      {{ $t("repeat_email-error") }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <!-- Phone Number -->
                <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group :label="`* ${$t('phone_number')}:`" label-for="phone-number">
                    <b-form-input
                      id="phone-number"
                      name="phone-number"
                      v-model="phoneNumber"
                      type="tel"
                      required
                    />
                    <small class="text-danger" v-if="error.phone_number">{{ error.phone_number[0] }}</small>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- Invoice Info Row -->
              <b-row class="mb-4">
                <b-col cols="12">
                  <h3>{{ $t("register_organization.invoice_information") }}</h3>
                  <span>{{ $t("register_organization.invoice_information_disclaimer") }}</span>
                </b-col>
                <!-- Company Org Name -->
                <b-col cols="12" class="mt-4">
                  <b-form-group :label="`* ${$t('register_organization.organization_name')}:`" label-for="org_name">
                    <b-form-input
                      :disabled="showInvoiceException"
                      id="org_name"
                      name="org_name"
                      v-model="orgName"
                      type="text"
                      required
                    />
                    <small class="text-danger" v-if="error.invoice_company_name">
                      {{ error.invoice_company_name[0] }}
                    </small>
                  </b-form-group>
                </b-col>
                <!-- Street Address -->
                <b-col cols="12">
                  <b-form-group :label="`* ${$t('register_organization.street_address')}:`" label-for="street_add">
                    <b-form-input
                      :disabled="showInvoiceException"
                      id="street_add"
                      name="street_add"
                      v-model="streetAdd"
                      type="text"
                      required
                    />
                    <small class="text-danger" v-if="error.invoice_street_address">
                      {{ error.invoice_street_address[0] }}
                    </small>
                  </b-form-group>
                </b-col>
                <!-- Zip Code -->
                <b-col cols="12" sm="12" md="4" lg="4">
                  <b-form-group :label="`* ${$t('register_organization.zip_code')}:`" label-for="zip_code">
                    <b-form-input
                      :disabled="showInvoiceException"
                      id="zip_code"
                      name="zip_code"
                      v-model="zipCode"
                      type="text"
                      required
                    />
                    <small class="text-danger" v-if="error.invoice_zip_code">
                      {{ error.invoice_zip_code[0] }}
                    </small>
                  </b-form-group>
                </b-col>
                <!-- Zip  -->
                <b-col cols="12" sm="12" md="8" lg="8">
                  <b-form-group :label="`* ${$t('register_organization.postal_district')}:`" label-for="zip">
                    <b-form-input
                      :disabled="showInvoiceException"
                      id="zip"
                      name="zip"
                      v-model="zip"
                      type="text"
                      required
                    />
                    <small class="text-danger" v-if="error.invoice_zip">
                      {{ error.invoice_zip[0] }}
                    </small>
                  </b-form-group>
                </b-col>
                <b-col :cols="showVAT ? 6 : 12">
                  <b-form-group :label="`* ${$t('country')}:`" label-class="text-nowrap" :label-for="country">
                    <b-form-select
                      style="min-width: 100px;"
                      id="country"
                      name="country"
                      v-model="country"
                      required
                      @change="testVat"
                    >
                      <b-form-select-option selected disabled value=""></b-form-select-option>
                      <b-form-select-option
                        v-for="countr in countries"
                        :value="countr.country_code"
                        :key="countr.country_code"
                      >
                        {{ countr.country_name }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <template v-if="showVAT">
                  <!-- VAT-number -->
                  <b-col cols="6">
                    <b-form-group :label="$t('register_organization.vat_number')" label-for="vat_number">
                      <b-form-input
                        :disabled="showInvoiceException"
                        id="vat_number"
                        name="vat_number"
                        v-model="vat_number"
                        type="text"
                        required
                        @input="vat_number_error = ''"
                        @change="testVat"
                      />
                      <small class="text-danger" v-if="vat_number_error">{{ vat_number_error }}</small>
                    </b-form-group>
                  </b-col>
                </template>
                <!-- Invoice Email Add  -->
                <b-col cols="12" sm="12" md="12" lg="12">
                  <b-form-group :label="$t('register_organization.invoice_email')" label-for="invoice_email">
                    <b-form-input
                      :disabled="showInvoiceException"
                      id="invoice_email"
                      name="invoice_email"
                      v-model="invoiceEmail"
                      type="email"
                    />
                    <small block>{{ $t("register_organization.invoice_email_disclaimer") }}</small>
                  </b-form-group>
                </b-col>
                <!-- Invoice reference  -->
                <b-col cols="12" sm="12" md="12" lg="12">
                  <b-form-group :label="`* ${$t('register_organization.invoice_reference')}:`" label-for="invoice_reference">
                    <b-form-input
                      :disabled="showInvoiceException"
                      id="invoice_reference"
                      name="invoice_reference"
                      v-model="invoiceReference"
                      type="text"
                      required
                    />
                    <small class="d-block text-danger" v-if="error.invoice_reference">
                      {{ error.invoice_reference[0] }}
                    </small>
                    <small block>{{ $t("register_organization.invoice_reference_disclaimer") }}</small>
                  </b-form-group>
                </b-col>
                <!-- Invoice reference Line2 -->
                <b-col cols="12" sm="12" md="12" lg="12">
                  <b-form-group :label="$t('register_organization.invoice_reference_2')" label-for="invoice_reference_line2">
                    <b-form-textarea
                      :disabled="showInvoiceException"
                      id="invoice_reference_line2"
                      v-model="invoiceReferenceLine2"
                      size="sm"
                      no-resize
                      rows="3"
                      max-rows="3"
                    ></b-form-textarea>
                    <small block>{{ $t("register_organization.invoice_reference_2_disclaimer") }}</small>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- No of participants -->
              <b-row class="mb-4">
                <b-col cols="12">
                  <h3>{{ $t("register_organization.participation_information") }}</h3>
                </b-col>
                <!-- No. of participants -->
                <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group :label="`* ${$t('register_organization.number_of_participants')}:`" label-for="no_participants">
                    <b-form-input
                      id="no_participants"
                      name="no_participants"
                      v-model="noParticipants"
                      type="number"
                      min="1"
                      required
                    />
                    <small v-if="isOrgParentIdNull" block>{{ $t("register_organization.participants_disclaimer") }}</small>
                    <small class="text-danger" v-if="error.participants">{{ error.participants[0] }}</small>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6" xl="6" v-if="(lang ? lang === 'se' : language === 'SE') && !isOrgParentIdNull">
                  <b-form-group :label="$t('register_organization.number_of_pedometers')" label-for="no_pedometers">
                    <b-form-input
                      id="no_pedometers"
                      name="no_pedometers"
                      v-model="noPedometers"
                      type="number"
                      min="0"
                    />
                    <small block>{{ $t("register_organization.pedometer_disclaimer") }}</small>
                  </b-form-group>
                </b-col>
                <!-- OtherComments -->
                <b-col cols="12" sm="12" md="12" lg="12" v-if="lang ? lang === 'se' : language === 'SE'">
                  <b-form-group :label="$t('register_organization.other_comments')" label-for="othercomments">
                    <b-form-textarea
                      id="othercomments"
                      v-model="otherComments"
                      size="sm"
                      no-resize
                      rows="3"
                      max-rows="3"
                    ></b-form-textarea>
                    <small block>{{ $t("register_organization.other_comments_disclaimer") }}</small>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- Delivery Information -->
              <transition name="fade">
                <b-row
                  class="mb-4"
                  v-if="
                    (lang ? lang === 'se' : language === 'SE') &&
                    noPedometers &&
                    parseInt(noPedometers, 10) > 0
                  "
                >
                  <b-col cols="12">
                    <h3>{{ $t("register_organization.delivery_information") }}</h3>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group :label="$t('register_organization.organization_name')" label-for="del_org">
                      <b-form-input
                        id="del_org"
                        name="del_org"
                        v-model="delOrgName"
                        type="text"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group :label="$t('register_organization.street_address')" label-for="del_street">
                      <b-form-input
                        id="del_street"
                        name="del_street"
                        v-model="delStreetAdd"
                        type="text"
                      />
                      <small class="text-danger">
                        {{ $t("register_organization.delivery_address_disclaimer") }}
                      </small>
                    </b-form-group>
                  </b-col>
                  <!-- Zip Code -->
                  <b-col cols="12" sm="12" md="4" lg="4">
                    <b-form-group :label="$t('register_organization.zip_code')" label-for="del_zip_code">
                      <b-form-input
                        id="del_zip_code"
                        name="del_zip_code"
                        v-model="delZipCode"
                        type="text"
                      />
                      <small class="text-danger">
                        {{ $t("register_organization.delivery_zip_code_disclaimer") }}
                      </small>
                    </b-form-group>
                  </b-col>
                  <!-- Zip  -->
                  <b-col cols="12" sm="12" md="8" lg="8">
                    <b-form-group :label="$t('register_organization.postal_district')" label-for="del_zip">
                      <b-form-input
                        id="del_zip"
                        name="del_zip"
                        v-model="delZip"
                        type="text"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </transition>

              <b-row class="mt-3">
                <b-col cols="12">
                  <b-button
                    block
                    size="lg"
                    type="submit"                                        
                    style="border-radius: 11px; font-size: 1.25rem; background-color: #00C782; color: white; border: none;"
                    :disabled="!isValid"
                    v-if="!spinnerLoading"
                  >
                    <span style="letter-spacing: 1px;">{{ $t("register_organization.send") }}</span>
                  </b-button>
                  <b-button style="border-radius: 11px; background-color: #00C782; color: white; border: none;" disabled v-if="spinnerLoading">
                    <b-spinner small></b-spinner>
                    <span class="sr-only">Loading...</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-container>
        </b-row>
      </b-overlay>
    </b-container>
  </div>
</template>


<script>
import axios from "axios"
import FormField from "@/components/FormField.vue"
export default {
  components: { FormField },
  watch: {
    language(newVal) {
      this.$store.dispatch("changeLanguage", newVal.toLowerCase())
    },
  },
  data() {
    return {
      language: this.$store.getters.language.toUpperCase(), // Default language
      loading: false,
      validateParent: [],
      isOrgParentIdNull: false,
      spinnerLoading: false,
      bg: this.$images.registerOrganizationBg,
      root_org_unit_id: null,
      organizational_parent_id: null,
      fName: "",
      lName: "",
      email: "",
      reEmail: "",
      phoneNumber: "",
      orgName: "",
      streetAdd: "",
      zipCode: "",
      zip: "",
      invoiceEmail: "",
      invoiceReference: "",
      invoiceReferenceLine2: "",
      noParticipants: "",
      selectOrganizationBelonging: null,
      error: {},
      lang: null,
      noPedometers: "",
      otherComments: "",
      delOrgName: "",
      delStreetAdd: "",
      delZipCode: "",
      delZip: "",
      showInvoiceException: null,
      countries: [],
      country: "",
      vat_number: "",
      vat_number_error: "",
      usePromoCodes: null,
      promotion_code: "",
      promotion_description: "",
    }
  },
  computed: {
    showVAT() {
      const currentCountry = this.countries.find(
        e => e.country_code === this.country
      )
      // fallback to not showing vat
      if (currentCountry) {
        return currentCountry?.ask_for_vat_number === "Y"
      } else {
        return false
      }
    },
    isValid() {
      var selectOrg
      if (!this.isOrgParentIdNull && !this.selectOrganizationBelonging) {
        selectOrg = false
      } else {
        selectOrg = true
      }
      return (
        this.fName &&
        this.lName &&
        this.email &&
        this.reEmail &&
        this.email === this.reEmail &&
        this.phoneNumber &&
        this.orgName &&
        this.streetAdd &&
        this.zipCode &&
        this.zip &&
        this.invoiceReference &&
        this.noParticipants &&
        selectOrg
      )
    },
    emailMatch() {
      if (this.reEmail) {
        return this.email === this.reEmail
      }
      return true
    },
  },
  methods: {
    async checkPromoCode() {
      this.promotion_description = ""
      try {
        const resp = await axios.get("/validatepromotioncode", {
          params: { promotion_code: this.promotion_code },
        })
        const data = resp.data?.data
        if (resp.status == 204) {
          this.promotion_description = this.$t(
            "register_organization.promo_code-invalid"
          )
        } else {
          // Else status is 200
          this.promotion_description = data.promotion_description
        }
      } catch ({ response }) {
        if (response.data.error_message === "The promotion code is not valid") {
          this.promotion_description = this.$t(
            "register_organization.promo_code-invalid"
          )
        }
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message || "Unable to validate promo code",
        })
        this.promotion_code = ""
      }
    },
    async getIfPromoCodes() {
      try {
        const resp = await axios.get("/promotioncodes", {
          params: {
            root_organizational_unit_id: this.root_org_unit_id,
          },
        })
        this.usePromoCodes = resp.data?.success ?? null
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text:
            response.data.error_message ||
            "Unable to get promo codes availability",
        })
      }
    },
    async testVat() {
      this.vat_number_error = ""
      if (this.showVAT) {
        if (!this.vat_number) {
          this.vat_number_error = this.$t("register_organization.enter_vat")
          return
        }
        try {
          const resp = await axios.post("/register-now/validatevat", {
            country_cd: this.country,
            vat_number: this.vat_number,
          })
          const data = resp.data?.data

          if (!data.valid_vat_number) {
            this.vat_number_error = this.$t(
              "register_organization.vat_number-invalid"
            )
          }
        } catch (err) {
          this.vat_number_error = this.$t(
            "register_organization.vat_number-invalid"
          )
        }
      }
    },
    async getCountries() {
      try {
        const resp = await axios.get("/register-now/countries")
        this.countries = resp.data?.data ?? []
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message || "Unable to get countries",
        })
      }
    },
    async checkInternational() {
      const resp = await axios.get("/organization/invoicexception", {
        params: { organizational_unit_id: this.selectOrganizationBelonging },
      })
      const { has_invoice_exception = null } = resp.data
      if (has_invoice_exception) {
        this.orgName = this.$t("register_organization.invoice_exception")
        this.streetAdd = "N/A"
        this.zipCode = "00000"
        this.zip = "N/A"
        this.invoiceEmail = "N/A"
        this.invoiceReference = "N/A"
        this.invoiceReferenceLine2 = "N/A"
      } else {
        this.orgName = ""
        this.streetAdd = ""
        this.zipCode = ""
        this.zip = ""
        this.invoiceEmail = ""
        this.invoiceReference = ""
        this.invoiceReferenceLine2 = ""
      }
      this.showInvoiceException = has_invoice_exception
    },

    resetInput() {
      this.fName = ""
      this.lName = ""
      this.email = ""
      this.reEmail = ""
      this.phoneNumber = ""
      this.orgName = ""
      this.streetAdd = ""
      this.zipCode = ""
      this.zip = ""
      this.invoiceEmail = ""
      this.invoiceReference = ""
      this.invoiceReferenceLine2 = ""
      this.noParticipants = ""
      this.selectOrganizationBelonging = null
      this.isOrgParentIdNull = false
      this.root_org_unit_id = null
      this.organizational_parent_id = null
      this.error = {}
      this.noPedometers = ""
      this.otherComments = ""
      this.delOrgName = ""
      this.delStreetAdd = ""
      this.delZipCode = ""
      this.delZip = ""
      this.country = ""
      this.vat_number = ""
      this.vat_number_error = ""
      this.usePromoCodes = null
      this.promotion_code = ""
      this.promotion_description = ""
    },
    async register() {
      this.spinnerLoading = true

      try {
        let data = {
          root_organizational_unit_id: this.root_org_unit_id,
          first_name: this.fName,
          last_name: this.lName,
          email: this.email,
          email_confirmation: this.reEmail,
          phone_number: this.phoneNumber,
          invoice_company_name: this.orgName,
          invoice_street_address: this.streetAdd,
          invoice_zip_code: this.zipCode,
          invoice_zip: this.zip,
          invoice_reference: this.invoiceReference,
          participants: this.noParticipants,
        }
        if (this.invoiceEmail) {
          data["invoice_email"] = this.invoiceEmail
        }
        if (this.invoiceReferenceLine2) {
          data["invoice_reference2"] = this.invoiceReferenceLine2
        }

        if (!this.selectOrganizationBelonging) {
          if (this.isOrgParentIdNull) {
            data["organizational_parent_id"] = null
          } else {
            data["organizational_parent_id"] = this.organizational_parent_id
          }
        } else {
          data["organizational_parent_id"] = this.selectOrganizationBelonging
        }

        const hasPedometers =
          this.noPedometers && parseInt(this.noPedometers, 10) > 0
        if (hasPedometers) {
          data["pedometers"] = this.noPedometers
        }
        if (this.otherComments) {
          data["comments"] = this.otherComments
        }
        if (hasPedometers && this.delOrgName) {
          data["del_company_name"] = this.delOrgName
        }
        if (hasPedometers && this.delZipCode) {
          data["del_zip_code"] = this.delZipCode
        }
        if (hasPedometers && this.delStreetAdd) {
          data["del_street_address"] = this.delStreetAdd
        }
        if (hasPedometers && this.delZip) {
          data["del_zip"] = this.delZip
        }

        data["plan"] = this.$route.query.plan
        data["lang"] = this.$route.query.lang
        data["invoice_country_code"] = this.country
        if (this.showVAT) {
          data["vat_number"] = this.vat_number
          data["valid_vat_number"] = Boolean(
            this.vat_number && !this.vat_number_error
          )
        }
        if (this.usePromoCodes && this.promotion_code) {
          data["promotion_code"] = this.promotion_code
        }

        const response = await axios.post(
          "/register-now/organization/save",
          data
        )
        if (response.status == 200) {
          localStorage.setItem("register-now-email", this.email)
          this.resetInput()
          this.$store.dispatch("addToastMessage", {
            type: "success",
            text: this.$t("register_organization.customer_registered"),
          })
          setTimeout(
            () => this.$router.push({ name: "RegisterConfirmation" }),
            100
          )
        }
      } catch ({ response }) {
        this.error = Object.assign(response.data.error_details)
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.spinnerLoading = false
      }
    },
    async validateActivationCode(url) {
      if (url) {
        this.loading = true

        try {
          const response = await axios.get(url)
          if (response instanceof Error) {
            throw response
          }
          this.validateParent = response.data?.data ?? []
          this.root_org_unit_id = this.validateParent?.[0]?.root_organizational_unit_id
          if (this.validateParent?.[0]?.organizational_parent_id === null) {
            this.isOrgParentIdNull = true
          } else {
            this.organizational_parent_id = this.validateParent?.[0]?.organizational_parent_id
          }
        } catch ({ response, name, message }) {
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: response?.data?.error_message ?? `${name}: ${message}`,
          })
        } finally {
          this.loading = false
        }
      }
    },
  },
  async created() {
    this.getCountries()

    const params = this.$route
    const url = params.fullPath

    const getLang = new URLSearchParams(url.split("?", 2)[1]).get("lang")
    this.lang = getLang
    if (this.$store.getters.languages.find(lang => this.lang == lang.code)) {
      this.$store.dispatch("changeLanguage", this.lang)
    }
    if (url) {
      await this.validateActivationCode(url)
      this.getIfPromoCodes()
    }
  },
}
</script>
<style scoped>
.bg-white {
  background-color: #ffffff;
}

.text-dark {
  color: #333333;
}

.text-danger {
  color: #e74c3c;
}

.form-group >>> .form-control {
  border-radius: 4px !important;
  border: 1px solid #ddd;
  padding: 0.75rem;
  font-size: 1rem;
}

.form-group >>> .custom-select {
  border-radius: 4px !important;
  border: 1px solid #ddd;
  padding: 0.75rem;
  font-size: 1rem;
}

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inactive-input {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  color: #777;
  cursor: not-allowed;
}

.no-gutter {
  padding-right: 0;
  padding-left: 0;
}

@media (min-width: 992px) {
  .left-col-border {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .right-col-border {
    border-top-right-radius: 0.85rem;
    border-bottom-right-radius: 0.85rem;
  }

  .rounded-left {
    border-top-left-radius: 0.85rem;
    border-bottom-left-radius: 0.85rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@media (min-width: 768px) {
  .custom-form-width {
    width: 65%;
    margin: 0 auto;
  }
}
</style>
